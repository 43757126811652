import React from 'react';
import styled from 'styled-components';

const NotificationListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background: linear-gradient(135deg, #f0f0f3, #ffffff);
  min-height: 100vh;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 2rem;
`;

const NotificationsList = styled.ul`
  list-style: none;
  width: 80%;
  padding: 0;
`;

const NotificationItem = styled.li`
  background: #1abc9c;
  color: white;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
`;

const MarkAsReadButton = styled.button`
  background: #e74c3c;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #c0392b;
  }
`;

const NotificationList = ({ notifications, handleMarkAsRead }) => {
  return (
    <NotificationListContainer>
      <Title>Notificações</Title>
      <NotificationsList>
        {notifications && notifications.length > 0 ? (
          notifications.map((notification) =>
            notification ? (
              <NotificationItem key={notification.id}>
                {notification.message}
                <MarkAsReadButton onClick={() => handleMarkAsRead(notification.id)}>Marcar como lida</MarkAsReadButton>
              </NotificationItem>
            ) : null
          )
        ) : (
          <p>Nenhuma notificação encontrada.</p>
        )}
      </NotificationsList>
    </NotificationListContainer>
  );
};

export default NotificationList;
