import axios from 'axios';

const api = axios.create({
  baseURL: 'https://ap1.gestormultiatendimento.com.br/api',
});

export const loginUser = async (credentials) => {
  try {
    const response = await api.post('/auth/login', credentials);
    return response.data;
  } catch (error) {
    console.error('Failed to login', error);
    throw error;
  }
};

export const logoutUser = () => {
  // Logic for logging out the user
};

export const getUser = async (token) => {
  try {
    const response = await api.get('/auth/me', {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch user', error);
    throw error;
  }
};

export default api;
