// Verify2FA.jsx
import React, { useState } from 'react';
import axios from '../axios'; // Importa a instância configurada do axios
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const Verify2FAContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
`;

const Input = styled.input`
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  background: #f9f9f9;
  transition: all 0.3s ease;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
  }
`;

const Button = styled.button`
  padding: 1rem;
  background: linear-gradient(45deg, #007bff, #0056b3);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.3);
  transition: all 0.3s ease;

  &:hover {
    background: linear-gradient(45deg, #0056b3, #007bff);
    box-shadow: 0 5px 15px rgba(0, 123, 255, 0.6);
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const Verify2FA = ({ userId, setAuthData, setError }) => {
  const [token, setToken] = useState('');
  const navigate = useNavigate();

  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      const jwtToken = localStorage.getItem('token');
      const response = await axios.post(
        '/api/auth/verify-2fa',
        { userId, token },
        { headers: { Authorization: `Bearer ${jwtToken}` } }
      );
      localStorage.setItem('token', response.data.accessToken);
      setAuthData(response.data.user); // Atualizando o contexto de autenticação
      navigate('/dashboard');
    } catch (error) {
      console.error('2FA Verification Error:', error);
      if (setError && typeof setError === 'function') {
        setError('Código 2FA inválido. Tente novamente.');
      }
    }
  };

  return (
    <Verify2FAContainer>
      <form onSubmit={handleVerify}>
        <Input
          type="text"
          placeholder="Digite o código 2FA"
          value={token}
          onChange={(e) => setToken(e.target.value)}
          required
        />
        <Button type="submit">Verificar</Button>
        {setError && typeof setError === 'function' && <ErrorMessage>{setError}</ErrorMessage>}
      </form>
    </Verify2FAContainer>
  );
};

export default Verify2FA;
