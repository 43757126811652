import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const UnauthorizedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #f0f0f3, #ffffff);
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #e74c3c;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #34495e;
  margin-bottom: 2rem;
`;

const Button = styled.button`
  padding: 1rem 2rem;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.3);
  transition: all 0.3s ease;

  &:hover {
    background: #2980b9;
    box-shadow: 0 5px 15px rgba(0, 123, 255, 0.6);
  }
`;

const Unauthorized = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/login');
  };

  return (
    <UnauthorizedContainer>
      <Title>Acesso Não Autorizado</Title>
      <Subtitle>Você não tem permissão para acessar esta página.</Subtitle>
      <Button onClick={handleGoBack}>Voltar ao Login</Button>
    </UnauthorizedContainer>
  );
};

export default Unauthorized;
