import React, { useState, useContext } from 'react';
import axios from '../axios'; // Importa a instância configurada do axios
import styled, { keyframes } from 'styled-components';
import { AuthContext } from '../contexts/AuthContext';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: linear-gradient(135deg, #f0f0f3, #ffffff);
  animation: ${fadeIn} 1s ease-in-out;
  min-height: 100vh;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 1s ease-in-out;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  padding: 1rem;
  animation: ${fadeIn} 1s ease-in-out;
`;

const Label = styled.label`
  font-size: 1.2rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 1rem;
  font-size: 1rem;
`;

const Button = styled.button`
  padding: 1rem;
  border-radius: 5px;
  border: none;
  background: #1abc9c;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #16a085;
  }
`;

const SendNotification = () => {
  const [message, setMessage] = useState('');
  const { authData } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/notifications/send', { message }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMessage('');
      alert('Notificação enviada com sucesso!');
    } catch (error) {
      console.error('Error sending notification:', error);
      alert('Erro ao enviar notificação.');
    }
  };

  return (
    <NotificationContainer>
      <Title>Enviar Notificação</Title>
      <Form onSubmit={handleSubmit}>
        <Label htmlFor="message">Mensagem</Label>
        <Input
          type="text"
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
        <Button type="submit">Enviar</Button>
      </Form>
    </NotificationContainer>
  );
};

export default SendNotification;
