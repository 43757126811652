// PrivateRoute.jsx

import React, { useContext, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { isAuthenticated } from '../utils/auth';
import axios from '../axios'; 

const PrivateRoute = ({ roles }) => {
  const { authData, setAuthData } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthenticated() && !authData) {
      const loadUser = async () => {
        const token = localStorage.getItem('token');
        try {
          const response = await axios.get('/api/auth/me', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setAuthData(response.data);
        } catch (error) {
          console.error('Failed to load user', error);
          localStorage.removeItem('token');
        }
      };

      loadUser();
    }
  }, [authData, setAuthData]);

  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  if (roles && roles.length > 0 && !roles.includes(authData?.role)) {
    return <Navigate to="/unauthorized" />;
  }

  return <Outlet />;
};

export default PrivateRoute;
