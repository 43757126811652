import React, { useState, useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from '../axios'; // Importa a instância configurada do axios
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
  animation: ${fadeIn} 1s ease-in-out;
`;

const RegisterForm = styled.form`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 3rem 4rem;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  animation: ${fadeIn} 1s ease-in-out;

  &:before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2));
    border-radius: 10px;
    z-index: -1;
    filter: blur(10px);
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 1rem;
  color: #343a40;
`;

const Subtitle = styled.p`
  text-align: center;
  margin-bottom: 2rem;
  color: #6c757d;
`;

const Input = styled.input`
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  background: #f9f9f9;
  transition: all 0.3s ease;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
  }
`;

const Select = styled.select`
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  background: #f9f9f9;
  transition: all 0.3s ease;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
  }
`;

const Button = styled.button`
  padding: 1rem;
  background: linear-gradient(45deg, #007bff, #0056b3);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.3);
  transition: all 0.3s ease;

  &:hover {
    background: linear-gradient(45deg, #0056b3, #007bff);
    box-shadow: 0 5px 15px rgba(0, 123, 255, 0.6);
  }
`;

const UserRegistration = () => {
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('user'); // Default role is 'user'
  const { authData } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/auth/register', {
        name, cpf, email, address, phone, password, role
      }, {
        headers: {
          Authorization: `Bearer ${authData.token}`,
        },
      });
      navigate('/login');
    } catch (error) {
      console.error('Registration error:', error);
    }
  };

  return (
    <RegisterContainer>
      <RegisterForm onSubmit={handleSubmit}>
        <Title>Registrar-se</Title>
        <Subtitle>Preencha os campos abaixo para criar uma conta</Subtitle>
        <Input
          type="text"
          placeholder="Nome"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <Input
          type="text"
          placeholder="CPF"
          value={cpf}
          onChange={(e) => setCpf(e.target.value)}
          required
        />
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Input
          type="text"
          placeholder="Endereço"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <Input
          type="text"
          placeholder="Telefone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Select value={role} onChange={(e) => setRole(e.target.value)}>
          <option value="user">Usuário</option>
          <option value="admin">Administrador</option>
        </Select>
        <Button type="submit">Registrar</Button>
      </RegisterForm>
    </RegisterContainer>
  );
};

export default UserRegistration;
