import React, { useEffect, useState, useContext } from 'react';
import axios from '../axios'; // Importa a instância configurada do axios
import styled, { keyframes } from 'styled-components';
import { AuthContext } from '../contexts/AuthContext';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
  animation: ${fadeIn} 1s ease-in-out;
  min-height: 100vh;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 1rem;
  color: #343a40;
`;

const Subtitle = styled.p`
  text-align: center;
  margin-bottom: 2rem;
  color: #343a40;
`;

const ReportTable = styled.table`
  width: 100%;
  max-width: 800px;
  border-collapse: collapse;
  margin-top: 1rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
`;

const TableHead = styled.thead`
  background-color: #007bff;
  color: white;
`;

const TableHeadCell = styled.th`
  padding: 1rem;
  text-align: left;
`;

const TableBody = styled.tbody`
  background-color: #f8f9fa;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #e9ecef;
  }
`;

const TableCell = styled.td`
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
`;

const Report = () => {
  const [queries, setQueries] = useState([]);
  const { authData } = useContext(AuthContext);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/api/reports/today', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        //console.log('Response Data:', response.data);
        setQueries(response.data);
      } catch (error) {
        console.error('Error fetching reports:', error);
      }
    };

    fetchReports();
  }, [authData]);

  return (
    <ReportContainer>
      <Title>Relatório de Consultas</Title>
      <Subtitle>Abaixo está o relatório das consultas realizadas</Subtitle>
      <ReportTable>
        <TableHead>
          <tr>
            <TableHeadCell>ID</TableHeadCell>
            <TableHeadCell>CPF Consultado</TableHeadCell>
            <TableHeadCell>Data da Consulta</TableHeadCell>
          </tr>
        </TableHead>
        <TableBody>
          {queries.map(query => (
            <TableRow key={query.id}>
              <TableCell>{query.id}</TableCell>
              <TableCell>{query.cpf}</TableCell>
              <TableCell>{new Date(query.queryTime).toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ReportTable>
    </ReportContainer>
  );
};

export default Report;
