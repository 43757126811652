import React, { useEffect, useState } from 'react';
import axios from '../axios'; // Importa a instância configurada do axios
import QRCode from 'qrcode.react';
import styled from 'styled-components';

const Setup2FAContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
`;

const QRCodeContainer = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 1rem;
  background: linear-gradient(45deg, #007bff, #0056b3);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.3);
  transition: all 0.3s ease;

  &:hover {
    background: linear-gradient(45deg, #0056b3, #007bff);
    box-shadow: 0 5px 15px rgba(0, 123, 255, 0.6);
  }
`;

const Setup2FA = ({ userId, setIs2FASetup, setIs2FARequired }) => {
  const [otpauthUrl, setOtpAuthUrl] = useState('');

  useEffect(() => {
    const setup2FA = async () => {
      try {
        const response = await axios.post(
          '/api/auth/setup-2fa',
          { userId },
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        setOtpAuthUrl(response.data.otpauthUrl);
      } catch (error) {
        console.error('Error setting up 2FA:', error);
      }
    };

    if (!otpauthUrl) {
      setup2FA();
    }
  }, [userId, otpauthUrl]);

  const handleContinue = () => {
    setIs2FASetup(false);
    setIs2FARequired(true);
  };

  return (
    <Setup2FAContainer>
      {otpauthUrl ? (
        <>
          <QRCode value={otpauthUrl} />
          <Button onClick={handleContinue}>Continuar</Button>
        </>
      ) : (
        <p>Configurando 2FA...</p>
      )}
    </Setup2FAContainer>
  );
};

export default Setup2FA;
