// AuthContext.jsx

import React, { createContext, useState, useEffect } from 'react';
import { getUser } from '../services/api';
import { isAuthenticated } from '../utils/auth';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadUser = async () => {
      if (isAuthenticated()) {
        const token = localStorage.getItem('token');
        try {
          const data = await getUser(token);
          setAuthData(data);
        } catch (error) {
          console.error('Failed to load user', error);
          localStorage.removeItem('token'); // Remover token inválido
        }
      }
      setLoading(false);
    };

    loadUser();
  }, []);

  const login = async (email, password) => {
    try {
      const data = await loginUser({ email, password });
      localStorage.setItem('token', data.accessToken);
      setAuthData(data.user);
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setAuthData(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ authData, setAuthData, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
