import React, { useState, useEffect } from 'react';
import axios from '../axios'; // Importa a instância configurada do axios
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
  animation: ${fadeIn} 1s ease-in-out;
  min-height: 100vh;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 1rem;
  color: #343a40;
`;

const Subtitle = styled.p`
  text-align: center;
  margin-bottom: 2rem;
  color: #6c757d;
`;

const UserList = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
  max-width: 800px;
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
  background: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
  }

  p {
    margin: 0.5rem 0;
    color: #495057;
    font-size: 1.1rem;
  }

  strong {
    color: #007bff;
    font-size: 1.2rem;
  }
`;

const UserManagement = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/users', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <UserContainer>
      <Title>Gerenciamento de Usuários</Title>
      <Subtitle>Gerencie os usuários cadastrados no sistema</Subtitle>
      <UserList>
        {users.map(user => (
          <ListItem key={user.id}>
            <p><strong>Nome:</strong> {user.name}</p>
            <p><strong>CPF:</strong> {user.cpf}</p>
            <p><strong>Email:</strong> {user.email}</p>
            <p><strong>Endereço:</strong> {user.address}</p>
            <p><strong>Telefone:</strong> {user.phone}</p>
            <p><strong>Créditos Disponíveis:</strong> {user.credits}</p>
          </ListItem>
        ))}
      </UserList>
    </UserContainer>
  );
};

export default UserManagement;
