import React from 'react';
import { createRoot } from 'react-dom/client'; // Use createRoot em vez de ReactDOM.render
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles, theme } from './styles';
import ErrorBoundary from './ErrorBoundary'; // Importe o ErrorBoundary

const container = document.getElementById('root');
const root = createRoot(container); // Crie o root com createRoot

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Router>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <App />
        </ThemeProvider>
      </Router>
    </ErrorBoundary>
  </React.StrictMode>
);
