import React, { useState, useContext } from 'react';
import axios from '../axios'; // Importa a instância configurada do axios
import styled, { keyframes } from 'styled-components';
import { AuthContext } from '../contexts/AuthContext';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const QueryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
  animation: ${fadeIn} 1s ease-in-out;
`;

const QueryForm = styled.form`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 3rem 4rem;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  animation: ${fadeIn} 1s ease-in-out;
  max-width: 500px;
  width: 100%;

  &:before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2));
    border-radius: 10px;
    z-index: -1;
    filter: blur(10px);
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 1rem;
  color: #343a40;
`;

const Subtitle = styled.p`
  text-align: center;
  margin-bottom: 2rem;
  color: #6c757d;
`;

const Input = styled.input`
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  background: #f9f9f9;
  transition: all 0.3s ease;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
  }
`;

const Button = styled.button`
  padding: 1rem;
  background: linear-gradient(45deg, #007bff, #0056b3);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.3);
  transition: all 0.3s ease;

  &:hover {
    background: linear-gradient(45deg, #0056b3, #007bff);
    box-shadow: 0 5px 15px rgba(0, 123, 255, 0.6);
  }
`;

const ResultCard = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  animation: ${fadeIn} 0.5s ease-in-out;
  text-align: center;
  max-width: 500px;
  width: 100%;
`;

const ResultMessage = styled.p`
  color: ${props => (props.error ? '#ff4d4d' : '#28a745')};
  font-size: 1.2rem;
`;

const RegistrationNumber = styled.p`
  font-size: 2rem;
  font-weight: bold;
  color: #007bff;
`;

const NewQuery = () => {
  const [cpf, setCpf] = useState('');
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [message, setMessage] = useState('');
  const [credits, setCredits] = useState(null);

  const handleNewQuery = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token'); // Recupera o token JWT do localStorage
      const response = await axios.post('/api/queries', 
        { cpf }, 
        {
          headers: {
            Authorization: `Bearer ${token}`, // Adiciona o token JWT no cabeçalho da requisição
          },
        }
      );
      setRegistrationNumber(response.data.registrationNumber);
      setMessage('Matrícula encontrada e consulta registrada com sucesso:');
      setCredits(response.data.credits); // Atualiza os créditos restantes no estado
    } catch (error) {
      console.error('Erro ao registrar consulta:', error);
      if (error.response && error.response.status === 403) {
        setMessage('Créditos insuficientes. Por favor, adicione mais créditos.');
      } else {
        setMessage('Erro ao registrar consulta.');
      }
      setRegistrationNumber('');
    }
  };

  return (
    <QueryContainer>
      <QueryForm onSubmit={handleNewQuery}>
        <Title>Consultar Matrícula</Title>
        <Subtitle>Informe o CPF para consultar a matrícula</Subtitle>
        <Input
          type="text"
          placeholder="CPF"
          value={cpf}
          onChange={(e) => setCpf(e.target.value)}
        />
        <Button type="submit">Consultar</Button>
      </QueryForm>
      {message && (
        <ResultCard>
          <ResultMessage error={!registrationNumber}>{message}</ResultMessage>
          {registrationNumber && (
            <RegistrationNumber>{registrationNumber}</RegistrationNumber>
          )}
          {credits !== null && (
            <p>Créditos restantes: {credits}</p>
          )}
        </ResultCard>
      )}
    </QueryContainer>
  );
};

export default NewQuery;
