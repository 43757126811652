// utils/auth.js

// Função para verificar se o usuário está autenticado
export const isAuthenticated = () => {
  const token = localStorage.getItem('token'); // Supondo que você tenha um token armazenado

  if (!token) {
    return false;
  }

  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const decodedToken = JSON.parse(jsonPayload);
    const currentTime = Date.now() / 1000; // Tempo atual em segundos

    // Verifique se o token está expirado
    if (decodedToken.exp < currentTime) {
      localStorage.removeItem('token'); // Remova o token expirado
      return false;
    }

    return true;
  } catch (error) {
    console.error('Error decoding token:', error);
    return false;
  }
};
