import React, { useState, useEffect } from 'react';
import axios from '../axios'; // Importa a instância configurada do axios
import styled, { keyframes } from 'styled-components';
import NotificationList from './NotificationList';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const NotificationPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: linear-gradient(135deg, #f0f0f3, #ffffff);
  animation: ${fadeIn} 1s ease-in-out;
  min-height: 100vh;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 1s ease-in-out;
`;

const NotificationPage = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const token = localStorage.getItem('token');
        console.log('Token:', token);
        const response = await axios.get('/api/notifications/unread', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log('Notificações recebidas do servidor:', response.data);
        const filteredNotifications = response.data.filter(notification => notification !== null);
        console.log('Notificações filtradas:', filteredNotifications);
        setNotifications(filteredNotifications);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, []);

  const handleMarkAsRead = async (notificationId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/notifications/mark-as-read', { notificationId }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification.id !== notificationId)
      );
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <NotificationPageContainer>
      <Title>Notificações</Title>
      <NotificationList notifications={notifications} handleMarkAsRead={handleMarkAsRead} />
    </NotificationPageContainer>
  );
};

export default NotificationPage;
