import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from '../axios';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ImportContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
  animation: ${fadeIn} 1s ease-in-out;
`;

const FileForm = styled.form`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 3rem 4rem;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  animation: ${fadeIn} 1s ease-in-out;
  max-width: 500px;
  width: 100%;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2));
    border-radius: 10px;
    z-index: -1;
    filter: blur(10px);
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 1rem;
  color: #343a40;
`;

const Subtitle = styled.p`
  text-align: center;
  margin-bottom: 2rem;
  color: #6c757d;
`;

const Input = styled.input`
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  background: #f9f9f9;
  transition: all 0.3s ease;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
  }
`;

const Button = styled.button`
  padding: 1rem;
  background: linear-gradient(45deg, #007bff, #0056b3);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.3);
  transition: all 0.3s ease;

  &:hover {
    background: linear-gradient(45deg, #0056b3, #007bff);
    box-shadow: 0 5px 15px rgba(0, 123, 255, 0.6);
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 1rem;
  background: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 1rem;
`;

const Progress = styled.div`
  height: 100%;
  width: ${props => props.progress}%;
  background: ${props => props.progress === 100 ? '#28a745' : '#007bff'};
  transition: width 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
`;

const Message = styled.p`
  margin-top: 1rem;
  color: ${props => (props.error ? '#ff4d4d' : '#28a745')};
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
`;

const FileImport = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [progress, setProgress] = useState(0);

  const handleFileUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    setProgress(0);

    try {
      const response = await axios.post('/api/files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        onUploadProgress: progressEvent => {
          const total = progressEvent.total;
          const current = progressEvent.loaded;
          const percentCompleted = Math.round((current / total) * 100);
          setProgress(percentCompleted);
        }
      });

      setMessage('Arquivo enviado com sucesso.');
      setProgress(100);
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('Erro ao enviar o arquivo.');
    }
  };

  return (
    <ImportContainer>
      <FileForm onSubmit={handleFileUpload}>
        <Title>Importar Arquivo</Title>
        <Subtitle>Selecione um arquivo para fazer upload</Subtitle>
        <Input
          type="file"
          onChange={(e) => setFile(e.target.files[0])}
          required
        />
        <Button type="submit">Enviar</Button>
        {progress > 0 && (
          <ProgressBar>
            <Progress progress={progress}>{progress}%</Progress>
          </ProgressBar>
        )}
      </FileForm>
      {message && <Message error={message.includes('Erro')}>{message}</Message>}
    </ImportContainer>
  );
};

export default FileImport;
