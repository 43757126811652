import React, { useState, useEffect } from 'react';
import axios from '../axios'; // Importa a instância configurada do axios
import styled, { keyframes } from 'styled-components';
import { FaUser } from 'react-icons/fa';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ProfileContainer = styled.div`
  padding: 2rem;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: 2rem auto;
  animation: ${fadeIn} 1s ease-in-out;
  text-align: center;
`;

const ProfileHeader = styled.h1`
  font-size: 2.5rem;
  color: #343a40;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 0.5rem;
  }
`;

const ProfileInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 1rem;
`;

const InfoItem = styled.div`
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }

  p {
    margin: 0;
    color: #495057;
    font-size: 1.1rem;
  }

  strong {
    color: #007bff;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
`;

const UserProfile = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not found');
        }
        //console.log('Token:', token); // Log do token para verificar se está sendo obtido corretamente
        const response = await axios.get('/api/users/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        //console.log('User Profile Data:', response.data); // Log da resposta para verificar os dados do usuário
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, []);

  return (
    <ProfileContainer>
      <ProfileHeader><FaUser /> Perfil do Usuário</ProfileHeader>
      {user && (
        <ProfileInfo>
          <InfoItem>
            <strong>Nome:</strong>
            <p>{user.name}</p>
          </InfoItem>
          <InfoItem>
            <strong>CPF:</strong>
            <p>{user.cpf}</p>
          </InfoItem>
          <InfoItem>
            <strong>Email:</strong>
            <p>{user.email}</p>
          </InfoItem>
          <InfoItem>
            <strong>Endereço:</strong>
            <p>{user.address}</p>
          </InfoItem>
          <InfoItem>
            <strong>Telefone:</strong>
            <p>{user.phone}</p>
          </InfoItem>
          <InfoItem>
            <strong>Créditos Disponíveis:</strong>
            <p>{user.credits}</p>
          </InfoItem>
        </ProfileInfo>
      )}
    </ProfileContainer>
  );
};

export default UserProfile;
