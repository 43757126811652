import React, { useState, useEffect } from 'react';
import axios from '../axios'; // Importa a instância configurada do axios
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const CreditContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #71b7e6, #9b59b6);
  animation: ${fadeIn} 1s ease-in-out;
`;

const CreditForm = styled.form`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 3rem 4rem;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  animation: ${fadeIn} 1s ease-in-out;
  max-width: 500px;
  width: 100%;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2));
    border-radius: 10px;
    z-index: -1;
    filter: blur(10px);
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 1rem;
  color: #343a40;
`;

const Subtitle = styled.p`
  text-align: center;
  margin-bottom: 2rem;
  color: #6c757d;
`;

const Input = styled.input`
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  background: #f9f9f9;
  transition: all 0.3s ease;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
  }
`;

const Select = styled.select`
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  background: #f9f9f9;
  transition: all 0.3s ease;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
  }
`;

const Button = styled.button`
  padding: 1rem;
  background: linear-gradient(45deg, #007bff, #0056b3);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.3);
  transition: all 0.3s ease;

  &:hover {
    background: linear-gradient(45deg, #0056b3, #007bff);
    box-shadow: 0 5px 15px rgba(0, 123, 255, 0.6);
  }
`;

const CreditManagement = () => {
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [creditAmount, setCreditAmount] = useState(0);
  const [token, setToken] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const storedToken = localStorage.getItem('token');
        setToken(storedToken);
        const response = await axios.get('/api/users', {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleCreditAddition = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/credits', {
        userId: selectedUserId,
        amount: creditAmount,
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      //console.log('Credits added successfully:', response.data);
    } catch (error) {
      console.error('Error adding credits:', error);
    }
  };

  return (
    <CreditContainer>
      <CreditForm onSubmit={handleCreditAddition}>
        <Title>Gerenciamento de Créditos</Title>
        <Subtitle>Selecione um usuário e adicione créditos</Subtitle>
        <Select value={selectedUserId} onChange={(e) => setSelectedUserId(e.target.value)} required>
          <option value="" disabled>Selecione um usuário</option>
          {users.map(user => (
            <option key={user.id} value={user.id}>{user.name} - {user.cpf}</option>
          ))}
        </Select>
        <Input
          type="number"
          placeholder="Créditos a adicionar"
          value={creditAmount}
          onChange={(e) => setCreditAmount(parseInt(e.target.value))}
          required
        />
        <Button type="submit">Adicionar Créditos</Button>
      </CreditForm>
    </CreditContainer>
  );
};

export default CreditManagement;
