// src/styles.js
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'Roboto', sans-serif; /* Exemplo de uma fonte elegante */
    background-color: #f8f9fa;
    color: #333;
    line-height: 1.6;
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    outline: none;
  }
`;

export const theme = {
  colors: {
    primary: '#007bff',   // Azul primário
    secondary: '#6c757d', // Cinza secundário
    success: '#28a745',   // Verde sucesso
    danger: '#dc3545',    // Vermelho perigo
    warning: '#ffc107',   // Amarelo aviso
    info: '#17a2b8',      // Azul informação
    light: '#f8f9fa',     // Cinza claro
    dark: '#343a40',      // Cinza escuro
  },
  breakpoints: {
    mobile: '576px',
    tablet: '768px',
    desktop: '992px',
    largeDesktop: '1200px',
  },
};
