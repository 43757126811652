// src/axios.js
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    //console.log('Token adicionado ao header:', token);
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

instance.interceptors.response.use((response) => {
  //console.log('Resposta recebida:', response);
  return response;
}, (error) => {
  //console.log('Erro na resposta:', error.response);
  return Promise.reject(error);
});

export default instance;
