import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import axios from '../axios'; // Importa a instância configurada do axios
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: linear-gradient(135deg, #f0f0f3, #ffffff);
  animation: ${fadeIn} 1s ease-in-out;
  min-height: 100vh;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 1s ease-in-out;
`;

const NotificationsList = styled.ul`
  list-style: none;
  width: 80%;
  padding: 0;
  animation: ${fadeIn} 1s ease-in-out;
`;

const NotificationItem = styled.li`
  background: #1abc9c;
  color: white;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.5s ease-in-out;
  display: flex;
  justify-content: space-between;
`;

const MarkAsReadButton = styled.button`
  background: #e74c3c;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #c0392b;
  }
`;

const RealTimeNotifications = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const socket = io('', {
      transports: ['websocket', 'polling'],
      withCredentials: true,
    });

    socket.on('connect', () => {
      console.log('Connected to server');
    });

    socket.on('notification', (data) => {
      console.log('New notification received:', data);
      setNotifications(prevNotifications => [...prevNotifications, data]);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    const fetchUnreadNotifications = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/api/notifications/unread', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log('Notificações recebidas do servidor:', response.data);
        const filteredNotifications = response.data.filter(notification => notification !== null);
        console.log('Notificações filtradas:', filteredNotifications);
        setNotifications(filteredNotifications);
      } catch (error) {
        console.error('Error fetching unread notifications:', error);
      }
    };

    fetchUnreadNotifications();
  }, []);

  const handleMarkAsRead = async (notificationId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/notifications/mark-as-read', { notificationId }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification.id !== notificationId)
      );
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <NotificationsContainer>
      <Title>Notificações em Tempo Real</Title>
      <NotificationsList>
        {notifications.map((notification) => (
          <NotificationItem key={notification.id}>
            {notification.message}
            <MarkAsReadButton onClick={() => handleMarkAsRead(notification.id)}>Marcar como lida</MarkAsReadButton>
          </NotificationItem>
        ))}
      </NotificationsList>
    </NotificationsContainer>
  );
};

export default RealTimeNotifications;
