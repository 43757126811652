import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './utils/PrivateRoute';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import NewQuery from './components/NewQuery';
import Report from './components/Report';
import UserRegistration from './components/UserRegistration';
import UserProfile from './components/UserProfile';
import CreditManagement from './components/CreditManagement';
import FileImport from './components/FileImport';
import UserManagement from './components/UserManagement';
import RealTimeNotifications from './components/RealTimeNotifications';
import Unauthorized from './components/Unauthorized';
import SendNotification from './components/SendNotification';
import NotificationPage from './components/NotificationPage';
import NotificationList from './components/NotificationList';
import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles, theme } from './styles';

const App = () => {
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    const handleKeyDown = (e) => {
      // F12
      if (e.keyCode === 123) {
        e.preventDefault();
      }
      // Ctrl+Shift+I (DevTools)
      if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
        e.preventDefault();
      }
      // Ctrl+U (View Source)
      if (e.ctrlKey && e.keyCode === 85) {
        e.preventDefault();
      }
      // Ctrl+Shift+J (DevTools)
      if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
        e.preventDefault();
      }
    };

    document.addEventListener('contextmenu', handleContextMenu);
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup on component unmount
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/new-query" element={<PrivateRoute roles={['admin', 'user']} />}>
              <Route path="" element={<NewQuery />} />
            </Route>
            <Route path="/report" element={<PrivateRoute roles={['admin', 'user']} />}>
              <Route path="" element={<Report />} />
            </Route>
            <Route path="/user-registration" element={<PrivateRoute roles={['admin']} />}>
              <Route path="" element={<UserRegistration />} />
            </Route>
            <Route path="/user-profile" element={<PrivateRoute roles={['admin', 'user']} />}>
              <Route path="" element={<UserProfile />} />
            </Route>
            <Route path="/credit-management" element={<PrivateRoute roles={['admin']} />}>
              <Route path="" element={<CreditManagement />} />
            </Route>
            <Route path="/file-import" element={<PrivateRoute roles={['admin']} />}>
              <Route path="" element={<FileImport />} />
            </Route>
            <Route path="/user-management" element={<PrivateRoute roles={['admin']} />}>
              <Route path="" element={<UserManagement />} />
            </Route>
            <Route path="/real-time-notifications" element={<PrivateRoute roles={['admin', 'user']} />}>
              <Route path="" element={<RealTimeNotifications />} />
            </Route>
            <Route path="/send-notification" element={<PrivateRoute roles={['admin']} />}>
              <Route path="" element={<SendNotification />} />
            </Route>
            <Route path="/notifications" element={<PrivateRoute roles={['admin', 'user']} />}>
              <Route path="" element={<NotificationPage />} />
            </Route>
            <Route path="/notificationList" element={<PrivateRoute roles={['admin', 'user']} />}>
              <Route path="" element={<NotificationList />} />
            </Route>
          </Route>
        </Routes>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
