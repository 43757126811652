import React, { useEffect, useState, useContext, useRef } from 'react';
import axios from '../axios'; // Importa a instância configurada do axios
import styled, { keyframes } from 'styled-components';
import { FaHome, FaUser, FaCreditCard, FaFileImport, FaChartLine, FaBell, FaSignOutAlt, FaUserPlus, FaUserCog, FaSearch, FaPaperPlane } from 'react-icons/fa';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { ptBR } from 'date-fns/locale';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('ptBR', ptBR);

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const DashboardContainer = styled.div`
  display: flex;
  background: linear-gradient(145deg, #f0f0f3, #ffffff);
  min-height: 100vh;
  padding: 2rem;
`;

const Sidebar = styled.div`
  width: 250px;
  height: 100vh;
  background: #2c3e50;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  animation: ${fadeIn} 0.5s ease-out;
`;

const SidebarItem = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
  border-radius: 10px;
  margin: 0.5rem 0;

  &:hover {
    background: #1abc9c;
    transform: translateX(10px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const SidebarIcon = styled.div`
  margin-right: 1rem;
`;

const SidebarText = styled.div`
  font-size: 1.2rem;
`;

const MainContent = styled.div`
  flex-grow: 1;
  padding: 2rem;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  margin-left: 2rem;
  animation: ${fadeIn} 0.5s ease-out;
`;

const ChartContainer = styled.div`
  width: 100%;
  height: 400px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-top: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #2c3e50;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
`;

const NotificationBell = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const NotificationCount = styled.span`
  position: absolute;
  top: -8px;
  right: -8px;
  background: #e74c3c;
  color: white;
  border-radius: 50%;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
`;

const CreditsInfo = styled.p`
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  background: #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: inline-block;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 1rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
`;

const FilterButton = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const Dashboard = () => {
  const [credits, setCredits] = useState(0);
  const [queries, setQueries] = useState([]);
  const { authData, setAuthData } = useContext(AuthContext);
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [unreadCount, setUnreadCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const userProfile = await axios.get('/api/auth/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCredits(userProfile.data.credits);
        fetchQueries(startDate, endDate);
        fetchUnreadNotifications();
      } catch (error) {
        console.error('Profile fetch error:', error);
      }
    };

    fetchData();
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [authData]);

  const fetchQueries = async (startDate, endDate) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/queries/date-range', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDate: startDate.toISOString().split('T')[0],
          endDate: endDate.toISOString().split('T')[0],
        },
      });
      setQueries(response.data);
      initializeChart(response.data);
    } catch (error) {
      console.error('Error fetching queries:', error);
    }
  };

  const fetchUnreadNotifications = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/notifications/unread', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUnreadCount(response.data.length);
    } catch (error) {
      console.error('Error fetching unread notifications:', error);
    }
  };

  const initializeChart = (queries) => {
    const ctx = chartRef.current.getContext('2d');
    const dates = queries.map(query => new Date(query.queryTime).toLocaleDateString('pt-BR'));
    const counts = dates.reduce((acc, date) => {
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});

    const labels = Object.keys(counts);
    const data = Object.values(counts);

    const chartData = {
      labels,
      datasets: [{
        label: 'Consultas',
        data,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }]
    };

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    chartInstance.current = new Chart(ctx, {
      type: 'bar',
      data: chartData,
      options: {
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Número de consultas'
            }
          },
          x: {
            title: {
              display: true,
              text: 'Data'
            }
          }
        }
      }
    });
  };

  const handleDateChange = () => {
    fetchQueries(startDate, endDate);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/auth/logout', {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      localStorage.removeItem('token');
      setAuthData(null);
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <DashboardContainer>
      <Sidebar>
        <SidebarItem onClick={() => handleNavigation('/dashboard')}>
          <SidebarIcon><FaHome /></SidebarIcon>
          <SidebarText>Dashboard</SidebarText>
        </SidebarItem>
        <SidebarItem onClick={() => handleNavigation('/user-profile')}>
          <SidebarIcon><FaUser /></SidebarIcon>
          <SidebarText>Perfil</SidebarText>
        </SidebarItem>
        {authData?.role === 'admin' && (
          <>
            <SidebarItem onClick={() => handleNavigation('/credit-management')}>
              <SidebarIcon><FaCreditCard /></SidebarIcon>
              <SidebarText>Gestão de Créditos</SidebarText>
            </SidebarItem>
            <SidebarItem onClick={() => handleNavigation('/new-query')}>
              <SidebarIcon><FaSearch /></SidebarIcon>
              <SidebarText>Nova Consulta</SidebarText>
            </SidebarItem>
            <SidebarItem onClick={() => handleNavigation('/file-import')}>
              <SidebarIcon><FaFileImport /></SidebarIcon>
              <SidebarText>Importar Arquivo</SidebarText>
            </SidebarItem>
            <SidebarItem onClick={() => handleNavigation('/report')}>
              <SidebarIcon><FaChartLine /></SidebarIcon>
              <SidebarText>Relatórios</SidebarText>
            </SidebarItem>
            <SidebarItem onClick={() => handleNavigation('/real-time-notifications')}>
              <SidebarIcon><FaBell /></SidebarIcon>
              <SidebarText>Notificações</SidebarText>
            </SidebarItem>
            <SidebarItem onClick={() => handleNavigation('/user-registration')}>
              <SidebarIcon><FaUserPlus /></SidebarIcon>
              <SidebarText>Registrar Usuário</SidebarText>
            </SidebarItem>
            <SidebarItem onClick={() => handleNavigation('/user-management')}>
              <SidebarIcon><FaUserCog /></SidebarIcon>
              <SidebarText>Gestão de Usuários</SidebarText>
            </SidebarItem>
            <SidebarItem onClick={() => handleNavigation('/send-notification')}>
              <SidebarIcon><FaPaperPlane /></SidebarIcon>
              <SidebarText>Enviar Notificação</SidebarText>
            </SidebarItem>
          </>
        )}
        {authData?.role === 'user' && (
          <>
            <SidebarItem onClick={() => handleNavigation('/new-query')}>
              <SidebarIcon><FaSearch /></SidebarIcon>
              <SidebarText>Nova Consulta</SidebarText>
            </SidebarItem>
            <SidebarItem onClick={() => handleNavigation('/report')}>
              <SidebarIcon><FaChartLine /></SidebarIcon>
              <SidebarText>Relatórios</SidebarText>
            </SidebarItem>
            <SidebarItem onClick={() => handleNavigation('/real-time-notifications')}>
              <SidebarIcon><FaBell /></SidebarIcon>
              <SidebarText>Notificações</SidebarText>
            </SidebarItem>
          </>
        )}
        <SidebarItem onClick={handleLogout}>
          <SidebarIcon><FaSignOutAlt /></SidebarIcon>
          <SidebarText>Logout</SidebarText>
        </SidebarItem>
      </Sidebar>
      <MainContent>
        <Header>
          <Title>Dashboard</Title>
          <NotificationBell onClick={() => handleNavigation('/real-time-notifications')}>
            <FaBell size={24} />
            {unreadCount > 0 && <NotificationCount>{unreadCount}</NotificationCount>}
          </NotificationBell>
        </Header>
        <CreditsInfo>Créditos Disponíveis: {credits}</CreditsInfo>
        <FiltersContainer>
          <DatePickerWrapper>
            <Label>Data Início:</Label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              locale="ptBR"
            />
          </DatePickerWrapper>
          <DatePickerWrapper>
            <Label>Data Fim:</Label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
              locale="ptBR"
            />
          </DatePickerWrapper>
          <FilterButton onClick={handleDateChange}>Filtrar</FilterButton>
        </FiltersContainer>
        <ChartContainer>
          <canvas id="myChart" ref={chartRef}></canvas>
        </ChartContainer>
      </MainContent>
    </DashboardContainer>
  );
};

export default Dashboard;
